import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
function LoginModal(props){
  return (
    <Modal {...props} className="modal fade" centered>
      <Modal.Header className="modal-header" closeButton>
        <h5 className="modal-title">Login Form</h5>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <div className="input-group mb-3">
          <span className="input-group-text">Email Id:</span>
          <input type="text" className="form-control" />
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text">Password:</span>
          <input type="password" className="form-control" />
        </div>
      </Modal.Body>
      <Modal.Footer>
      <Button className="btn btn-primary float-end">
          Login
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export default LoginModal;
import TimeLine from "../controls/timeline";
function About(){
    return(
        <section id="about" className="about section">
            {/* Section Title */}
            <div className="container section-title" data-aos="fade-up">
                <h2>About</h2>
                <p>Seasoned Software Developer with 10 Years of enriched Experience in Client/Server and Web-Based Applications Using C#, ASP.NET, EF, SQL Server, ADO.NET, LINQ, MVC, and Web API. Proven expertise in analysis, design, and development with Dot Net.</p>
            </div>
            {/* End Section Title */}
            <div className="container" data-aos="fade-up" data-aos-delay="100">
                <div className="row gy-4 justify-content-center">
                    <div className="col-lg-4">
                        <img src="assets/img/my-profile-img.png" className="img-fluid" alt=""/>
                    </div>
                    <div className="col-lg-8 content">
                        <h2>Full Stack Developer &amp; Part Time Bike Rider(<i className="bi bi-bicycle"></i>).</h2>
                        <p className="fst-italic py-3">
                        I'm an introvert who enjoys spending time alone or with family. I'm a passionate biker, always up for long rides just for the thrill of it. I love coding, watching movies, and cherishing moments with my family.
                        </p>
                        <div className="row">
                            <div className="col-lg-6">
                                <ul>
                                    <li><i className="bi bi-chevron-right"></i><strong>Birthday:</strong><span>May 03, 1987</span></li>
                                    <li><i className="bi bi-chevron-right"></i><strong>Website:</strong><span>ravikanthudda.com</span></li>
                                    <li><i className="bi bi-chevron-right"></i><strong>Phone:</strong><span>+91-9990-965-066</span></li>
                                    <li><i className="bi bi-chevron-right"></i><strong>City:</strong><span>Noida, India</span></li>
                                </ul>
                            </div>
                            <div className="col-lg-6">
                                <ul>
                                    <li><i className="bi bi-chevron-right"></i><strong>Age:</strong><span>37</span></li>
                                    <li><i className="bi bi-chevron-right"></i><strong>Degree:</strong><span>Master</span></li>
                                    <li><i className="bi bi-chevron-right"></i><strong>Email:</strong>ravikanthudda@gmail.com</li>
                                    <li style={{'display':'none'}}><i className="bi bi-chevron-right"></i><strong>Freelance:</strong><span>Available</span></li>
                                </ul>
                            </div>
                        </div>
                        <p className="py-3">
                        Over the course of my 14-year journey as a Full Stack Developer and .NET Trainer, I've not only evolved professionally but also personally. Starting as a beginner in the tech world, I gradually mastered the complexities of both front-end and back-end development. Along the way, I got married, welcoming new responsibilities and joys into my life. The birth of my first child, buying my first car, and moving into our first home were milestones that marked significant chapters in my story. Amidst all this, I faced numerous ups and downs, but each challenge only strengthened my resolve to grow, both as a professional and as a person. Today, I stand as an expert, driven by the experiences that have shaped me, and passionate about mentoring others on their own journeys.
                        </p>
                    </div>
                    <div className="col-lg-8 content" style={{display:"none"}}>
                        <TimeLine/>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default About;
import React, { useEffect, useState } from "react";
import Header from "./components/header";
import Main from "./components/main";
import Footer from './components/footer';
import { BrowserRouter as Router, Link } from 'react-router-dom';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkIfLoaded = () => {
      if (document.readyState === "complete") {
        setTimeout(() => setLoading(false), 1000);
      }
    };

    checkIfLoaded();
    document.addEventListener("readystatechange", checkIfLoaded);

    return () => {
      document.removeEventListener("readystatechange", checkIfLoaded);
    };
  }, []);

  return (
    <Router>
      <div className="App">
        {loading ? (
          <div id="preloader" className="preloader"></div>
        ) : (
          <>
            <Header />
            <Main />
            <Footer />
          </>
        )}

        <Link
          to="#"
          id="scroll-top"
          className="scroll-top d-flex align-items-center justify-content-center"
        >
          <i className="bi bi-arrow-up short"></i>
        </Link>
      </div>
    </Router>
  );
}

export default App;

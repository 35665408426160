import React from "react";
import {Link} from 'react-router-dom';
function NavMenu(){
    return(
        <nav id="navmenu" className="navmenu">
            <ul>
                <li><Link to="#hero" className="active"><i className="bi bi-house navicon"></i>Home</Link></li>
                <li><Link to="#about"><i className="bi bi-person navicon"></i>About</Link></li>
                <li><Link to="#resume"><i className="bi bi-file-earmark-text navicon"></i>Resume </Link></li>
                 <li><Link to="#Blogs"><i className="bi bi-images navicon"></i>Blogs</Link></li>
                {/* <li style={{'display':'none'}}><Link to="#services"><i className="bi bi-hdd-stack navicon"></i>Services</Link></li> */}                
                <li><Link to="#contact"><i className="bi bi-envelope navicon"></i>Contact</Link></li>                
            </ul>
        </nav>
    );
}
export default NavMenu;
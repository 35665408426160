import React from 'react';
import NavMenu from "./navmenu";
import LoginModal from './loginModal';
import {Link} from 'react-router-dom';
function Header(){
    const [modalShow, setModalShow] = React.useState(false);
    return(
        <header id="header" className="header dark-background d-flex flex-column">
        <i className="header-toggle d-xl-none bi bi-list"></i>
        <div className="profile-img">
          <img src="assets/img/my-profile-img.png" alt="" className="img-fluid rounded-circle"/>
        </div>
        <Link to="#" className="logo d-flex align-items-center justify-content-center">
          <h1 className="sitename">Ravikant Hudda</h1>
        </Link>
        <div className="social-links text=center">
          <Link to="https://x.com/ravikant_hudda" className="twitter" target="_blank" rel="noreferrer"><i className="bi bi-twitter-x"></i></Link>
          <Link to="https://www.facebook.com/ravikant.hudda" className="facebook" target="_blank" rel="noreferrer"><i className="bi bi-facebook"></i></Link>
          <Link to="https://www.instagram.com/ravikanthooda/" className="instagram" target="_blank" rel="noreferrer"><i className="bi bi-instagram"></i></Link>
          <Link to="https://www.naukri.com/mnjuser/profile" className="google-plus" target="_blank" rel="noreferrer"><i className="bi bi-skype"></i></Link>
          <Link to="https://www.linkedin.com/in/ravikant-hudda/" className="linkedin" target="_blank" rel="noreferrer"><i className="bi bi-linkedin"></i></Link>   
          <Link onClick={()=> setModalShow(true)}>
          <i className="bi bi-key"></i>  
          </Link>       
        </div>
        <NavMenu/>        
        <LoginModal show={modalShow} onHide={()=>setModalShow(false)}/>
      </header>      
    );
}
export default Header;
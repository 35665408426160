import React,{useEffect} from 'react';
import AOS from 'aos';
import Typed from 'typed.js';
import '../App.css';

const Hero=()=>{
  useEffect(()=>{
    const options = {
      strings: ["Developer","Designer","Philanthropist","Pragmatic","Biker"],
      typeSpeed: 50,
      backSpeed: 25,
      backDelay: 1000,
      startDelay: 500,
      loop: true,
    };
    const typed = new Typed(".typed",options);
    AOS.init({duration:1000});
    return()=>{
      typed.destroy();
    };   
  },[]);
  return (
    <section id="hero" className="hero section dark-background">
    <img src="assets/img/hero-bg.jpg" alt="" data-aos="fade-in" className="hero-image" />
    <div className="container" data-aos="fade-up" data-aos-delay="100">
      <h2>Ravikant Hudda</h2>
      <p>
        I'm <span className="typed"></span>
      </p>
    </div>
  </section>
  );
}
export default Hero;

import React from "react";
import Hero from "../sections/hero";
import About from '../sections/about';
import Skills from "../sections/skills";
import Resume from '../sections/resume';
// import Portfolio from '../sections/portfolio';
// import Services from '../sections/services';
// import Testmonials from '../sections/testimonials';
import Contact from '../sections/contact';
function Main(){    
    return(
    <main className="main">
        <Hero/>
        <About/>
        <Skills/>
        <Resume/>
        {/* <Portfolio/> */}
        {/* <Services/> */}
        {/* <Testmonials/> */}
        <Contact/>
    </main>
    );
}
export default Main;